import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

/**
 * Returns component containing page SEO optimizations.
 *
 * @returns {React.Component} Page SEO.
 */
const SEO = () => {
  const title = 'Page Not Found';
  const domain = 'https://willcodeforsushi.com';
  const canonicalURL = `${domain}/404/`;
  const logoTwitter = `${domain}/logo/logo-twitter-card.png`;
  const logoOpenGraph = `${domain}/logo/logo-opengraph-card.png`;
  const description = 'The page you are looking for cannot be found.';

  // JSON-LD WebPage schema.
  const webPageSchema = {
    '@context': 'https://schema.org',
    '@id': 'https://schema.org/WebPage',
    '@type': 'WebPage',
    url: canonicalURL,
    name: title,
  };

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={canonicalURL} />
      <meta name="description" content={description} />
      <meta name="robots" content="nofollow, noindex" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={logoTwitter} />
      <meta name="twitter:image:alt" content={description} />
      <meta name="og:url" content={canonicalURL} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={logoOpenGraph} />
      <meta name="og:locale" content="en_CA" />
      <script type="application/ld+json">
        {JSON.stringify(webPageSchema, null, 2)}
      </script>
    </Helmet>
  );
};

/**
 * Sets a global style to target tags outside the React Virtual DOM.
 *
 * @returns {React.Component} Styled component.
 */
const GlobalStyle = createGlobalStyle`
  body {
    background-color: #0a2200;
    color: #fff;

    margin: 0;

    font-family: monospace;
    font-size: 16px;
  }
`;

/**
 * Component container.
 *
 * @returns {React.Component} Styled component.
 */
const Container = styled.main`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  height: 100vh;

  font-size: 2rem;
  text-align: center;

  margin: 25px;

  a {
    color: inherit;
    font-size: 1rem;
    text-decoration: none;
  }
`;

/**
 * Error 404 page component.
 *
 * @returns {React.Component} 404 page.
 */
const NotFoundPage = () => (
  <Container>
    <GlobalStyle />
    <SEO />

    <h1>Error 404: Sushi not found</h1>

    <p>
      <Link to="/">Click here to go home.</Link>
    </p>
  </Container>
);

export default NotFoundPage;
